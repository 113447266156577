import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Options, LabelType } from "ng5-slider";
import {
  faStopwatch,
  faLaptopHouse,
  faSearchLocation,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { EstateService } from "src/app/services/estate.service";
import {
  AbstractControl,
  FormControl,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Router } from "@angular/router";
import { createMask } from "@ngneat/input-mask";
import { VisitorsService } from "src/app/services/visitors.service";
import { CognitoUserInterface } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Title, Meta } from "@angular/platform-browser";
import Swal from "sweetalert2";

function autocompleteObjectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === "string") {
      return null;
    }
    return null; /* valid option selected */
  };
}

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  faCheckCircle = faCheckCircle;

  user: CognitoUserInterface | undefined;

  ipaddress: string = "";

  currencyInputMask = createMask({
    alias: "numeric",
    groupSeparator: ",",
    digits: 0,
    prefix: "$ ",
  });
  minValue = new FormControl("20000000", Validators.required);
  maxValue = new FormControl("70000000000");
  barrio = new FormControl(""); //,Validators.required);

  public municipioAutocompleteControl = new FormControl("", {
    validators: [autocompleteObjectValidator(), Validators.required],
  });

  public validation_msgs = {
    municipioAutocompleteControl: [
      {
        type: "required",
        message:
          "Municipio no reconocido, por favor selecciona uno de la lista",
      },
    ],
    minValue: [
      { type: "required", message: "Se debe ingresar un valor mayor a 0" },
    ],
    barrioControl: [
      { type: "required", message: "Se debe ingresar un Barrio" },
    ],
  };

  propertyTypes: any[] = [];
  filteredCities!: Observable<string[]>;
  selectedPropertyType: string = "Apartamento";
  selectedOperation: string = "venta";
  selectedMunicipio: string = "";
  faStopwatch = faStopwatch;
  faLaptopHouse = faLaptopHouse;
  faSearchLocation = faSearchLocation;
  faSpinner = faSpinner;
  loading: boolean = false;

  cities: string[] = [];

  constructor(
    private visitorsService: VisitorsService,
    private estateService: EstateService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private title: Title,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.getLocation();
    this.title.setTitle("Homty - Metabuscador inmobiliario");
    this.meta.addTag({
      name: "description",
      content: "Homty metabuscador inmobiliario",
    });
    this.meta.addTag({
      name: "keywords",
      content:
        "homty, inmobiliario, metabuscador, arriendo, venta, propiedades",
    });
    // this.visitorsService.getIpAddress().subscribe((res: { ip: string; }) => {
    //   this.ipaddress = res.ip;
    //   this.visitorsService.getGEOLocation(this.ipaddress).subscribe((res: { city: any; }) => {
    //     this.selectedMunicipio = res.city
    //   });
    // });

    Auth.currentUserInfo().then((user) => {
      this.user = user;
    });
    this.getCities();
    this.getPropertyTypes();
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  getLocation(): void {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          if (permissionStatus.state == "granted") {
            this.loading = true;
          }
        });
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.loading = true;
          const longitude = position.coords.longitude;
          const latitude = position.coords.latitude;
          this.visitorsService
            .callApi(longitude, latitude)
            .subscribe((res: { city: any }) => {
              if (res.city != "") {
                this.selectedMunicipio = res.city;
              } else {
                this.selectedMunicipio = "Medellín";
              }
              this.loading = false;
            });
        },
        (error) => {
          this.selectedMunicipio = "Medellín";
          this.loading = false;
        }
      );
    } else {
      this.selectedMunicipio = "Medellín";
      this.loading = false;
    }
  }

  getPropertyTypes() {
    this.estateService.getPropertyTypes().subscribe((resp) => {
      this.propertyTypes = resp;
    });
  }

  getCities() {
    this.estateService.getCities().subscribe((cities) => {
      for (let i = 0; i < cities.length; i++) {
        this.cities.push(cities[i].Municipio);
      }

      this.filteredCities = this.municipioAutocompleteControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value))
      );
    });
  }

  updateMunicipioSelection(value: string) {
    this.selectedMunicipio = value;
  }

  searchProperties() {
    if (this.municipioAutocompleteControl.valid && this.minValue.valid) {
      if (this.selectedMunicipio == "") {
        this.selectedMunicipio = "Medellín";
      }

      Auth.currentUserInfo().then((user) => {
        this.user = user;
        if (this.user && (this.user["attributes"]["custom:active_plan"] != 0)) {
          this.router.navigate(["/propiedades"], {
            queryParams: {
              inmueble: this.selectedPropertyType,
              operacion: this.selectedOperation,
              municipio: this.selectedMunicipio,
              barrio: this.barrio.value,
              preciomin: this.minValue.value.replace(/[^0-9]/g, ""),
              preciomax: this.maxValue.value.replace(/[^0-9]/g, ""),
            },
          });
        } else {
          if (this.user && (this.user["attributes"]["custom:free_trial"] == 1 || this.user["attributes"]["custom:active_plan"] == 0)) {
            Swal.fire({
              title: "Opción no disponible",
              text: "No cuentas con un plan activo, ¿Deseas adquirir uno?",
              showCancelButton: true,
              confirmButtonColor: "#fc6641",
              confirmButtonText: "Comprar",
              cancelButtonText: "Cancelar",
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(["pay-subscription"]);
              }
            });
          } else {
            Swal.fire({
              title: "Opción no disponible",
              text: "Debes registrarte, ¿Deseas Registrarte?",
              showCancelButton: true,
              confirmButtonColor: "#fc6641",
              confirmButtonText: "Registrate",
              cancelButtonText: "Cancelar",
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(["/login/signUp"]);
              }
            });
          }
        }
      });
    }
  }

  updatePrices(event: any) {
    if (event.value == "arriendo") {
      this.minValue.setValue("400000");
      this.maxValue.setValue("200000000");
    } else {
      this.minValue.setValue("20000000");
      this.maxValue.setValue("70000000000");
    }
  }

  signUp() {
    this.router.navigate(["/login/signUp"]);
  }

  search() {
    this.router.navigate(["/propiedades"]);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.cities.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
